import React from 'react'
import { Snackbar, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
   root: {
      top: theme.spacing(9)
   }
}))

export default function Notificaciones(props) {

   const { notify, setNotify } = props;
   const classes = useStyles()

   const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setNotify({
            ...notify,
            isOpen: false
      })
   }

   return (
      <Snackbar
            className={classes.root}
            open={notify.isOpen}
            autoHideDuration={notify.duration}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            onClose={handleClose}>
            <Alert
               variant="filled"
               severity={notify.type}
               onClose={handleClose}>
               {notify.message}
            </Alert>
      </Snackbar>
   )
}
