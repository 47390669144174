import React from "react"

const Error404 = () => (

//Fragment

  <>

		{/* Mensaje cuando llegue a página incorrecta */}

    <p>Uy, llegaste a un mundo desconocido. Mejor regresa al inicio.</p>

  </>

)

export default Error404