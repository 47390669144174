import React, {useEffect, useState} from 'react';
import ReactPDF, {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import { Button } from '@material-ui/core/';
// import QRCode from "react-qr-code";
import QRCode from 'qrcode';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  pageBackground: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    height: '100%',
    width: '100%',
  },
  // nombresapellidos:{
  //   position: 'relative',
  //   top: 210,
  //   left: 100,
  //   fontSize: '26',
  //   textTransform: 'uppercase',
  //   textAlign: 'center',
  //   fontFamily: 'Oswald'
  // },
  // codigoqr:{
  //   position: 'absolute',
  //   flexGrow: 1,
  // }

})

Font.register({
  family: 'Oswald',
  src: '/font/Oswald.ttf'
});

export default function App() {
  

  var opts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    quality: 0.3,
    margin: 1,
    color: {
      dark:"#fff",
      light:"#000088"
    }
  }
  const [codqr, setCodqr] = useState("");
  QRCode.toDataURL('Curso Nacional: Módulo IV - Herramientas Administrativas y Pedagógicas para la educación Virtual a Distancia y Presencial. Participante: Kathya Lorena Gonzalez Justiniano C.I.: 6233541', opts)
    .then(img => {
      setCodqr(img);
      //  console.log(img);
  })
  

  const MyDoc = () => (
    <Document>
      <Page size="Letter" orientation="landscape" style={styles.page}>
        <Image src="/img/certificados/ce8_4.jpg" style={styles.pageBackground} />
        {/* <View style={styles.section}>
            <Text style={{position: 'relative',
                top: 220,
                left: 100,
                fontSize: '26',
                textTransform: 'uppercase',
                textAlign: 'center',
                fontFamily: 'Oswald'}}> Lic. ANDREAN SABINA Viviana Condori Quispe llanos </Text>
        </View>
        <View style={{
              top: 150, 
              left: 680, 
              position: 'absolute',}}>
            <Image cache={false} style={{width:90, height:90}} src={codqr} crossorigin="anonymous"/>
        </View>
        <View style={{
              top: 135, 
              left: 680, 
              position: 'absolute',}}>
            <Text style={{
                fontSize: '14',
                textTransform: 'uppercase',
                textAlign: 'center'}}> 1809990 </Text>
        </View> */}


        <View style={styles.section}>
            <Text style={{position: 'relative',
                top: 180,
                left: 50,
                marginRight: 150,
                fontSize: '26',
                textTransform: 'uppercase',
                textAlign: 'center',
                fontFamily: 'Oswald'}}> Kathya Lorena Gonzalez Justiniano </Text>
        </View>
        <View style={{
              top: 175, 
              left: 680,
              position: 'absolute',}}>
            <Image cache={false} style={{width:80, height:80}} src={codqr} crossorigin="anonymous"/>
        </View>
        <View style={{
              top: 160, 
              left: 680, 
              position: 'absolute',}}>
            <Text style={{
                fontSize: '14',
                textTransform: 'uppercase',
                textAlign: 'center'}}> 6233541 </Text>
        </View>


        
      </Page>
    </Document>
  )
  
  const Appp = () => (
    <div>
      <PDFDownloadLink document={<MyDoc />} fileName="somename.pdf">
        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
      </PDFDownloadLink>
    </div>
  )

  return (
    <div>
      <Button onClick={()=>Appp()}>
        PDF expotar
      </Button>
      <img src={codqr}></img>
      {/* <QRCode value="Hola Mundo" size={156} bgColor="#282c34" fgColor="#fff" level="H" /> */}
      <Appp />
     </div>
  );
}
