import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import Routes from './routes/Routes';
ReactDOM.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// LO QUE TE PUEDE SERVIR
// PARA CARGAR ASINCRONAMENTE DATOS https://github.com/fnaquira/react-lazy-example
// ICONOS DE MATERIAL UI REACT  https://material.io/resources/icons/?style=baseline
// PARA VALIDAR CARACTERES: https://developer.mozilla.org/es/docs/Web/JavaScript/Referencia/Objetos_globales/RegExp
// ACTIVAR O DESACTIVAR BOTON SI NO ETA VALIDAD FORMULARIO:  https://progexpertos.com/q/41213/react-enable-button-after-all-form-fields-are-not-empty
// EJEMPLOS PEQUEÑOS:  https://bezkoder.com/react-hooks-crud-axios-api/
// REACT TO PDF :   https://www.npmjs.com/package/react-to-pdf
// GENERAR CODIGOS QR en REACT:   https://binary-coffee.dev/post/como-crear-codigos-qr-con-react
// QR CODE- LA QUE USAMOS  :  https://www.npmjs.com/package/qrcode
// PARA COMPARTIR:  https://ed.team/blog/como-crear-botones-de-redes-sociales-sin-programar
reportWebVitals();
