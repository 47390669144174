import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Card,
  Link,
  Grid,
  Paper,
  Typography,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Divider,
  Modal } from '@material-ui/core/';
  import {
    GetApp, 
    Search, 
    Edit, 
    Delete, 
    Add, 
    Save, 
    Cancel, 
    Check, 
    Visibility,
    Send
  } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
  sidebarAboutBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
  },
  sidebarSection: {
    marginTop: theme.spacing(3),
  },
  media: {
    height: 140,
  },
  modal: {
    position: 'fixed',
    width: '360px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #ccc',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1,1,1),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  iconos:{
      cursor: 'pointer'
  },
}));

export default function Sidebar(props) {
  const classes = useStyles();
  const { archives, description, social, title } = props;
  const [modalNeytiri, setModalNeytiri] = useState(false);
  const abrirCerrarModalNeytiri = (post) =>{
    setModalNeytiri(!modalNeytiri);
  }
  const [modalLaptop, setModalLaptop] = useState(false);
  const abrirCerrarModalLaptop = (post) =>{
    setModalLaptop(!modalLaptop);
  }
  const bodyNeytiri = (
    <div className={classes.modal}>
       <h3>Servicios de NEYTIRI</h3>
        <img src="/img/servicios/tarjeta1.jpg" width="100%" />
        <div align="right">
          <Button
             variant="outlined"
             color="default"
             size="small"
             className={classes.button}
             startIcon={<Cancel />}
             onClick={()=>abrirCerrarModalNeytiri()}>
             Cerrar
          </Button>
       </div>
    </div>
  )

  const bodyLaptop = (
    <div className={classes.modal}>
       <h3>Servicio de MANTENIMIENTO DE LAPTOPs</h3>
        <img src="/img/servicios/tarjeta2.jpg" width="100%" />
        <div align="right">
          <Button
             variant="outlined"
             color="default"
             size="small"
             className={classes.button}
             startIcon={<Cancel />}
             onClick={()=>abrirCerrarModalLaptop()}>
             Cerrar
          </Button>
       </div>
    </div>
  )

  return (
    <Grid item xs={12} md={4}>
      <Paper elevation={0} className={classes.sidebarAboutBox}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography>{description}</Typography>
      </Paper>
      <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
        Servicios extras
      </Typography>
      {archives.map((archive) => (
        <Link 
          display="block" 
          variant="body1" 
          href={archive.url} 
          key={archive.title}
          rel="noreferrer" // rel="noopener"
          target="_blank">
          {archive.title}
          <Typography className={classes.pos} color="textSecondary">
          {archive.descripcion}
          </Typography>
          <Divider /><br />
        </Link>
      ))}


      <Card className={classes.root}>
      <CardActionArea onClick={()=>abrirCerrarModalNeytiri()}>
        <CardMedia
          className={classes.media}
          image="/img/servicios/logoneytiri.jpg"
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            NEYTIRI
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            SERVICIO DE: Serigrafiado en poleras, gorras, CD's, DVD's, empastados, etc.
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {/* <Link 
              display="block" 
              variant="body1"
              href= "https://api.whatsapp.com/send?phone=59168023629 &text= Le escribo por el servicio de serigrafia"
              rel="noreferrer" // rel="noopener"
              target="_blank"> 
            <Button
              variant="contained"
              color="primary"
              className="ViewButton"
              startIcon={<Send />}
              size="small">
                Escribir
            </Button>
        </Link>
        <Button size="small" color="primary" onClick={()=>abrirCerrarModalNeytiri()}>
          Ver más
        </Button> */}
      </CardActions>
    </Card>



    <Divider /><br />
      <Card className={classes.root}>
      <CardActionArea onClick={()=>abrirCerrarModalLaptop()}>
        <CardMedia
          className={classes.media}
          image="/img/servicios/laptop.jpg"
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            SERVICIO DE MANTENIMIENTO DE LAPTOPs
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Se realiza mantenimiento preventivo de LAPTOPs marcas; Lenovo, Dell, Samsung, HP, Toshiba, Acer, Fuijitsu, Sony, Huawei, LG, Macbook, Asus, Compaq, Quipus, etc.
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {/* <Link 
              display="block" 
              variant="body1"
              href= "https://api.whatsapp.com/send?phone=59168023629 &text= Le escribo por el servicio de MANTENIMIENTO DE LAPTOP"
              rel="noreferrer" // rel="noopener"
              target="_blank"> 
            <Button
              variant="contained"
              color="primary"
              className="ViewButton"
              startIcon={<Send />}
              size="small">
                Escribir
            </Button>
        </Link>
        <Button size="small" color="primary" onClick={()=>abrirCerrarModalLaptop()}>
          Ver más
        </Button> */}
      </CardActions>
    </Card>


    <Divider /><br />
      <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image="/img/servicios/zoom.jpg"
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            AMPLIAR EL TIEMPO DE TU ZOOM
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Cansado de tener solo 40 minutos para utilizar ZOOM con tus estudiantes, participantes o reuniones?
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Link 
              display="block" 
              variant="body1"
              href= "https://api.whatsapp.com/send?phone=59162536641 &text= Le escribo sobre como ampliar mi zoom para mis clases virtuales."
              rel="noreferrer" // rel="noopener"
              target="_blank"> 
            <Button
              variant="contained"
              color="primary"
              className="ViewButton"
              startIcon={<Send />}
              size="small">
                Solicitar información
            </Button>
        </Link>
      </CardActions>
    </Card>


      <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
        Nuestras redes sociales
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Déjanos tu like y únete a nuestros grupos donde enviamos información actualizada, material educativo, cursos, talleres y el link de nuestras sesiones.
      </Typography>
      {social.map((network) => (
        <Link 
          display="block" 
          variant="body1" 
          href={network.url} 
          key={network}
          rel="noreferrer" // rel="noopener"
          target="_blank">
          <Grid container direction="row" spacing={1} alignItems="center">
            <Grid item>
              <network.icon />
            </Grid>
            <Grid item>{network.name}</Grid>
          </Grid>
        </Link>
      ))}
          <Modal
            open = {modalNeytiri}
            onClose = {abrirCerrarModalNeytiri}>
              {bodyNeytiri}
          </Modal>
          <Modal
            open = {modalLaptop}
            onClose = {abrirCerrarModalLaptop}>
              {bodyLaptop}
          </Modal>
    </Grid>
  );
}

Sidebar.propTypes = {
  archives: PropTypes.array,
  description: PropTypes.string,
  social: PropTypes.array,
  title: PropTypes.string,
};