import React, { Component } from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom';
// import Login from '../pages/Login';
import Blog from '../pages/Blog';
import Cursos2020 from '../pages/Cursos2020';
import Cursos2021 from '../pages/Cursos2021';
import Error404 from '../pages/Error404';
import App from '../App';
function Routes() {
   return (
      <BrowserRouter>
         <Switch>
            <Route exact path='/' component={Blog} />
            <Route exact path='/cursos2020' component={Cursos2020} />
            <Route exact path='/cursos2021' component={Cursos2021} />
            <Route exact path='/app' component={App} />
            <Route component={Error404}/>
         </Switch>
      </BrowserRouter>
   );
}
export default Routes;