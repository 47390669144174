import React from 'react';
import Carousel from "react-material-ui-carousel"
import autoBind from "auto-bind"
// import '../style//Example.scss';
import MessageIcon from '@material-ui/icons/Message';

import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Grid,
    Button,
    Icon,
    Checkbox,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
    Slider,
    Link
} from '@material-ui/core';

function Banner(props) {
    if (props.newProp) console.log(props.newProp)
    const contentPosition = props.contentPosition ? props.contentPosition : "left"
    const totalItems = props.length ? props.length : 3;
    const mediaLength = totalItems - 1;

    let items = [];
    const content = (
        <Grid item xs={12 / totalItems} key="content">
            <CardContent className="Content">
                <Typography className="Title">
                    {props.item.Name}
                </Typography>

                <Typography className="Caption">
                    {props.item.Caption}
                </Typography>

                <Link 
                          display="block" 
                          variant="body1" 
                        //   href= "https://api.whatsapp.com/send?phone=59162536641 &text= Quiero obtener el material digital y las sesiones grabadas en DVD"
                        href= ""
                          rel="noreferrer" // rel="noopener"
                          target="_blank"> 
                          <Button
                    variant="contained"
                    color="primary"
                    className="ViewButton"
                    endIcon={<MessageIcon />}
                    size="small">
                    Pedido
                    </Button>
                </Link>
            </CardContent>
        </Grid>
    )


    for (let i = 0; i < mediaLength; i++) {
        const item = props.item.Items[i];

        const media = (
            <Grid item xs={12 / totalItems} key={item.Name}>
                <CardMedia
                    className="Media"
                    image={item.Image}
                    title={item.Name}
                >
                    <Typography className="MediaCaption">
                        {item.Name}
                    </Typography>
                </CardMedia>

            </Grid>
        )

        items.push(media);
    }

    if (contentPosition === "left") {
        items.unshift(content);
    } else if (contentPosition === "right") {
        items.push(content);
    } else if (contentPosition === "middle") {
        items.splice(items.length / 2, 0, content);
    }

    return (
        <Card raised className="Banner">
            <Grid container spacing={0} className="BannerGrid">
                {items}
            </Grid>
        </Card>
    )
}

const items = [
    {
        Name: "",
        Caption: "",
        contentPosition: "right", // left
        Items: [
            {
                Name: "SESIONES GRABADAS:",
                Image: ""
            },
            {
                Name: "Cada sesión queda grabada y publicada en nuestra plataforma, tambien puede solicitar en DVD.",
                Image: ""
            }
        ]
    },
    {
        Name: "",
        Caption: "",
        contentPosition: "right", // middle
        Items: [
            {
                Name: "MATERIAL DIGITAL:",
                Image: "" // /img/ban1.jpg
            },
            {
                Name: "Los materiales digitales que se utiliza en cada sesión puede solicitar en DVD y físico.",
                Image: ""
            }
        ]
    }
]

class BannerExample extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            autoPlay: true,
            animation: "fade",
            indicators: true,
            timeout: 500,
            navButtonsAlwaysVisible: false,
            navButtonsAlwaysInvisible: false
        }

        autoBind(this);
    }

    toggleAutoPlay() {
        this.setState({
            autoPlay: !this.state.autoPlay
        })
    }

    toggleIndicators() {
        this.setState({
            indicators: !this.state.indicators
        })
    }

    toggleNavButtonsAlwaysVisible() {
        this.setState({
            navButtonsAlwaysVisible: !this.state.navButtonsAlwaysVisible
        })
    }

    toggleNavButtonsAlwaysInvisible() {
        this.setState({
            navButtonsAlwaysInvisible: !this.state.navButtonsAlwaysInvisible
        })
    }

    changeAnimation(event) {
        this.setState({
            animation: event.target.value
        })
    }

    changeTimeout(event, value) {
        this.setState({
            timeout: value
        })
    }

    render() {
        return (
            <div style={{ marginTop: "10px", color: "#494949"}}>
                <Carousel
                    className="Example"
                    autoPlay={true}
                    animation="fade" // fade -  slide
                    indicators={true}
                    timeout={1000}
                    navButtonsAlwaysVisible={false} // relieve de los botoncitos a los costados
                    navButtonsAlwaysInvisible={false} // invisible totalmente a los botoncitos del costado
                    next={(now, previous) => console.log(`Next User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
                    prev={(now, previous) => console.log(`Prev User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
                    onChange={(now, previous) => console.log(`OnChange User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
                >
                    {
                        items.map((item, index) => {
                            return <Banner item={item} key={index} contentPosition={item.contentPosition} />
                        })
                    }
                </Carousel>
            </div>
        )
    }
}

export default BannerExample;