import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SendTelegram from '@material-ui/icons/Telegram';
import FacebookIcon from '@material-ui/icons/Facebook';
import BlockIcon from '@material-ui/icons/Block';
import TwitterIcon from '@material-ui/icons/Twitter';
import YoutubeIcon from '@material-ui/icons/YouTube';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import MessageRoundedIcon from '@material-ui/icons/MessageRounded';
import Header2 from './Header2';
import Main2 from './Main2';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Carrusel from '../componentes/Carrusel';


const useStyles = makeStyles((theme) => ({
    mainGrid: {
        marginTop: theme.spacing(3),
    }
}));

const posts = [
    { 
        id: 15,
        titulo: "CURSO NACIONAL: Centralizado de calificaciones al registro pedagógico, desde la plataforma educativa Moodle y otras plataformas.",
        img: '/img/cursosytalleres/c12.jpg', 
        descripcion: "CURSO NACIONAL: Centralizado de calificaciones al rregistro pedagógico, desde la plataforma educativa Moodle y otras plataformas como classroom, kahoot y realizar evaluaciones dinamicas.",
        fechapublicacion: '14 de Abril de 2021',
        activo: BlockIcon, // CheckOutlinedIcon   BlockIcon
        registrarse: true,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_cm',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: .',
                sesionlink: '',
            }
        ],

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/cm.jpg',
        orientacion: 'landscape',
        nombrestop: 197,
        nombresleft: 90,
        nombresright: 100,
        qrtop: 178,
        qrleft: 680,
        citop: 162,
        cileft: 680,
    },
    { 
        id: 14,
        titulo: "CURSO INTERNACIONAL: Google for education - nivel Avanzado.",
        img: '/img/cursosytalleres/c11_2.jpg', 
        descripcion: "CURSO INTERNACIONAL: Google for education - nivel Avanzado, Certificado con valor curricular de 80 horas académicas.",
        fechapublicacion: '01 de Abril de 2021',
        activo: BlockIcon, // CheckOutlinedIcon   BlockIcon
        registrarse: false,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_gfeia',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: Elabora tu blog informativo educativo.',
                sesionlink: '',
            }
        ],

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/internacional1/gfea.jpg',
        orientacion: 'landscape',
        nombrestop: 200,
        nombresleft: 90,
        nombresright: 100,
        qrtop: 184,
        qrleft: 680,
        citop: 169,
        cileft: 680,
    },
    { 
        id: 13,
        titulo: "CURSO INTERNACIONAL: Google for education - nivel Básico.",
        img: '/img/cursosytalleres/c11_1.jpg', 
        descripcion: "CURSO INTERNACIONAL: Google for education - nivel Básico, Certificado con valor curricular de 80 horas académicas.",
        fechapublicacion: '01 de Abril de 2021',
        activo: BlockIcon, // CheckOutlinedIcon   BlockIcon
        registrarse: false,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_gfeib',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: Elabora tu blog informativo educativo.',
                sesionlink: '',
            }
        ],

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/internacional1/gfeb.jpg',
        orientacion: 'landscape',
        nombrestop: 200,
        nombresleft: 90,
        nombresright: 100,
        qrtop: 184,
        qrleft: 680,
        citop: 169,
        cileft: 680,
    },
    { 
        id: 12,
        titulo: "CURSO NACIONAL: Manejo y administración de la Plataforma MINEDU - Bolivia, Secundaria Comunitaria Productiva.",
        img: '/img/cursosytalleres/c10_3.jpg', 
        descripcion: "3er CICLO: CURSO NACIONAL - Manejo y administración de la Plataforma MINEDU - Bolivia: Para maetros de nivel secundaria. Certificado con valor curricular de 120 horas académicas.",
        fechapublicacion: '5 de marzo de 2021',
        activo: BlockIcon, //BlockIcon - CheckOutlinedIcon
        registrarse: false,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_c3msec',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: Plataforma educativa MINEDU - Bolivia.',
                sesionlink: 'https://fb.watch/44-7GGJMum/'
            },
            {
                sesiondesc: 'SESIÓN 2: Plataforma educativa MINEDU - Bolivia.',
                sesionlink: 'https://fb.watch/4AJo_P9ksb/'
            },
            {
                sesiondesc: 'SESIÓN 3: Plataforma educativa MINEDU - Bolivia.',
                sesionlink: 'https://fb.watch/4AJqbdLNvA/'
            },
            {
                sesiondesc: 'SESIÓN 4: Plataforma educativa MINEDU - Bolivia.',
                sesionlink: 'https://fb.watch/4AJqZoV5AC/'
            }
        ],

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/3ciclo/sec.jpg',
        orientacion: 'landscape',
        nombrestop: 190,
        nombresleft: 90,
        nombresright: 100,
        qrtop: 171,
        qrleft: 680,
        citop: 157,
        cileft: 680,
    },
    { 
        id: 11,
        titulo: "CURSO NACIONAL: Manejo y administración de la Plataforma Educativa MINEDU - Bolivia, Primaria Comunitaria Vocacional.", 
        img: '/img/cursosytalleres/c10_2.jpg', 
        descripcion: "3er CICLO: CURSO NACIONAL - Manejo y administración de la Plataforma MINEDU - Bolivia: Para maetros de nivel primaria. Certificado con valor curricular de 120 horas académicas.",
        fechapublicacion: '4 de marzo de 2021',
        activo: BlockIcon, //BlockIcon - CheckOutlinedIcon
        registrarse: false,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_c3mprim',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: Plataforma educativa MINEDU - Bolivia.',
                sesionlink: 'https://fb.watch/44-6TtuhQO/'
            },
            {
                sesiondesc: 'SESIÓN 2: Plataforma educativa MINEDU - Bolivia.',
                sesionlink: 'https://fb.watch/4AJen9BXKC/'
            },
            {
                sesiondesc: 'SESIÓN 3: Plataforma educativa MINEDU - Bolivia.',
                sesionlink: 'https://fb.watch/4AJfIDMVqt/'
            },
            {
                sesiondesc: 'SESIÓN 4: Plataforma educativa MINEDU - Bolivia.',
                sesionlink: 'https://fb.watch/4AJgTPvo7Q/'
            }
        ],

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/3ciclo/prim.jpg',
        orientacion: 'landscape',
        nombrestop: 190,
        nombresleft: 90,
        nombresright: 100,
        qrtop: 171,
        qrleft: 680,
        citop: 157,
        cileft: 680,
    },
    { 
        id: 10,
        titulo: "CURSO NACIONAL: Manejo y administración de la Plataforma Educativa MINEDU - Bolivia, Inicial en Familia Comunitaria.", 
        img: '/img/cursosytalleres/c10_1.jpg', 
        descripcion: "3er CICLO: CURSO NACIONAL - Manejo y administración de la Plataforma MINEDU - Bolivia: Para maetros de nivel inicial. Certificado con valor curricular de 120 horas académicas.",
        fechapublicacion: '3 de marzo de 2021',
        activo: BlockIcon, //BlockIcon - CheckOutlinedIcon
        registrarse: false,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_c3mini',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: Plataforma educativa MINEDU - Bolivia.',
                sesionlink: 'https://fb.watch/44-53VrLyH/'
            },
            {
                sesiondesc: 'SESIÓN 2: Plataforma educativa MINEDU - Bolivia.',
                sesionlink: 'https://fb.watch/4AJ6txY0Cm/'
            },
            {
                sesiondesc: 'SESIÓN 3: Plataforma educativa MINEDU - Bolivia.',
                sesionlink: 'https://fb.watch/4AJ9kkJBLz/'
            }
        ],

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/3ciclo/mini.jpg',
        orientacion: 'landscape',
        nombrestop: 190,
        nombresleft: 90,
        nombresright: 100,
        qrtop: 171,
        qrleft: 680,
        citop: 157,
        cileft: 680,
    },
    /* { 
        id: 9,
        titulo: "CURSO INTERNACIONAL: TIC's, TAC's y herramientas para la educación semi presencial.",
        img: '/img/cursosytalleres/c9.jpg', 
        descripcion: "CURSO INTERNACIONAL: TIC's, TAC's y herramientas para la educación semi presencial, Certificado con valor curricular de 180 horas académicas.",
        fechapublicacion: '4 de febrero de 2021',
        activo: BlockIcon,
        registrarse: true,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_internacional1',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: Elabora tu blog informativo educativo.',
                sesionlink: 'https://fb.watch/44_qNItwfa/',
            },
            {
                sesiondesc: 'SESIÓN 2: Aplicaciones y programas informáticos para tus clases.',
                sesionlink: 'https://fb.watch/44_xNUK4JH/',
            },
            {
                sesiondesc: 'SESIÓN 3: TIC, TAC, TEP conceptos y herramientas.',
                sesionlink: 'https://fb.watch/44_Dw0f2R9/',
            },
            {
                sesiondesc: 'SESIÓN 4: Aplicaciones virtuales para la educacion a distancia y presencial.',
                sesionlink: 'https://fb.watch/44_FRzzAFT/',
            },
            {
                sesiondesc: 'SESIÓN 5: MOODLE Plataforma virtual del MINEDU - Bolivia.',
                sesionlink: 'https://fb.watch/44_Kn52MvZ/',
            },
        ],

        habilitardescarga: 'N',
        // imgcertificado: '/img/certificados/c9_tac.jpg',
        imgcertificado: '',
        orientacion: 'portrait',
        nombrestop: 320,
        nombresleft: 35,
        nombresright: 100,
        qrtop: 308,
        qrleft: 500,
        citop: 295,
        cileft: 500,
    }, */
    {
        id: 8,
        titulo: 'CERTIFICADO GENERAL - Curso Nacional: Herramientas administrativas y pedagógicas para la educación virtual, a distancia y presencial.',
        img: '/img/cursosytalleres/c8_5.jpg', 
        descripcion: '2do CICLO: Herramientas administrativas y pedagógicas para la educación virtual, a distancia y presencial, Certificado con valor curricular de 300 horas académicas. Para obtener éste certificado debe obtener los módulos I al IV.',
        fechapublicacion: '19 de febrero de 2021',
        activo: BlockIcon,
        registrarse: false,
        certificado: true,
        sesiones: false,
        cdescarga: 'f_c2vdpmg',

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/2ciclo/c8_mg.jpg',
        orientacion: 'landscape',
        nombrestop: 230,
        nombresleft: 90,
        nombresright: 100,
        qrtop: 190,
        qrleft: 690,
        citop: 175,
        cileft: 690,
    },
    { 
        id: 7,
        titulo: 'MÓDULO IV - Curso Nacional: Herramientas administrativas y pedagógicas para la educación virtual, a distancia y presencial.',
        img: '/img/cursosytalleres/c8_4.jpg', 
        descripcion: '2do CICLO: Herramientas administrativas y pedagógicas para la educación virtual, a distancia y presencial, Certificado con valor curricular de 72 horas académicas.',
        fechapublicacion: '12 de febrero de 2021',
        activo: BlockIcon,
        registrarse: false,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_c2vdpm4',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: Elaboración del contenido para la guía didactica.',
                sesionlink: 'https://fb.watch/44ZXZ4JY3y/',
            },
            {
                sesiondesc: 'SESIÓN 2: Subir video a canal de YouTube, hacer un QR de un enlace.',
                sesionlink: 'https://fb.watch/44_f3PY-lF/',
            },
            {
                sesiondesc: 'SESIÓN 3: Plan de diagnóstico y plan desarrollo curricular.',
                sesionlink: 'https://fb.watch/44_i2UzfiH/',
            },
        ],

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/2ciclo/c8_m4.jpg',
        orientacion: 'landscape',
        nombrestop: 200,
        nombresleft: 90,
        nombresright: 100,
        qrtop: 171,
        qrleft: 690,
        citop: 157,
        cileft: 690,
    },
    { 
        id: 6,
        titulo: 'MÓDULO III - Curso Nacional: Herramientas administrativas y pedagógicas para la educación virtual, a distancia y presencial.',
        img: '/img/cursosytalleres/c8_3.jpg', 
        descripcion: '2do CICLO: Herramientas administrativas y pedagógicas para la educación virtual, a distancia y presencial, Certificado con valor curricular de 72 horas académicas.',
        fechapublicacion: '1 de febrero de 2021',
        activo: BlockIcon,
        registrarse: false,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_c2vdpm3',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: Elaboración de guías didáctica. 1ra PARTE.',
                sesionlink: 'https://fb.watch/44ZFJN_wWg/',
            },
            {
                sesiondesc: 'SESIÓN 2: Edición de videos educativos con Filmora 9.',
                sesionlink: 'https://fb.watch/44ZMvUDOJZ/',
            },
            {
                sesiondesc: 'SESIÓN 3: Elaboración de la portada para la guía didáctica.',
                sesionlink: 'https://fb.watch/44ZRSvSm4L/',
            }
        ],

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/2ciclo/c8_m3.jpg',
        orientacion: 'landscape',
        nombrestop: 200,
        nombresleft: 90,
        nombresright: 100,
        qrtop: 171,
        qrleft: 690,
        citop: 157,
        cileft: 690,
    },
    { 
        id: 5,
        titulo: 'MÓDULO II - Curso Nacional: Herramientas administrativas y pedagógicas para la educación virtual, a distancia y presencial.',
        img: '/img/cursosytalleres/c8_2.jpg', 
        descripcion: '2do CICLO: Herramientas administrativas y pedagógicas para la educación virtual, a distancia y presencial, Certificado con valor curricular de 72 horas académicas.',
        fechapublicacion: '22 de enero de 2021',
        activo: BlockIcon,
        registrarse: false,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_c2vdpm2',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: Telegram, Skype y Signal para la educación.',
                sesionlink: 'https://fb.watch/44ZwRFfn2r/',
            },
            {
                sesiondesc: 'SESIÓN 2: PUBLISHER Folletos, presentaciones, programas, boletines y más.',
                sesionlink: 'https://fb.watch/44ZBuZ4HO9/',
            },
            {
                sesiondesc: 'SESIÓN 3: Prevención médica contra el COVID19, al volver a clases presenciales.',
                sesionlink: 'https://fb.watch/44_4jNhm-I/',
            },
        ],

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/2ciclo/c8_m2.jpg',
        orientacion: 'landscape',
        nombrestop: 200,
        nombresleft: 90,
        nombresright: 100,
        qrtop: 171,
        qrleft: 690,
        citop: 157,
        cileft: 690,
    },
    { 
        id: 4,
        titulo: 'MÓDULO I - Curso Nacional: Herramientas administrativas y pedagógicas para la educación virtual, a distancia y presencial.',
        img: '/img/cursosytalleres/c8_1.jpg', 
        descripcion: '2do CICLO: Herramientas administrativas y pedagógicas para la educación virtual, a distancia y presencial, Certificado con valor curricular de 72 horas académicas.',
        fechapublicacion: '13 de enero de 2021',
        activo: BlockIcon,
        registrarse: false,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_c2vdpm1',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: Microsoft word avanzado.',
                sesionlink: 'https://fb.watch/44Z5JP10_c/',
            },
            {
                sesiondesc: 'SESIÓN 2: Elaboración de horarios automatizados con App',
                sesionlink: 'https://fb.watch/44ZiZP2mlB/',
            },
            {
                sesiondesc: 'SESIÓN 2: CONTINUACIÓN... Elaboración de horarios automatizados con App',
                sesionlink: 'https://fb.watch/44ZmoPnoeO/',
            },
            {
                sesiondesc: 'SESIÓN 3: Control académico avanzado con excel',
                sesionlink: 'https://fb.watch/44ZplTTRtS/',
            },
            {
                sesiondesc: 'SESIÓN 4: Internet para la educación',
                sesionlink: 'https://fb.watch/44ZqkUUbIK/',
            },
        ],

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/2ciclo/c8_m1.jpg',
        orientacion: 'landscape',
        nombrestop: 200,
        nombresleft: 80,
        nombresright: 100,
        qrtop: 171,
        qrleft: 690,
        citop: 157,
        cileft: 690,
    },
    { 
        id: 3,
        titulo: 'CERTIFICADO GENERAL - Formación de Facilitadores en Plataformas y Herramientas Virtuales para la Educación', 
        img: '/img/cursosytalleres/c7_3.jpg',
        descripcion: 'Formación de Facilitadores en Plataformas y Herramientas Virtuales para la Educación, Certificado con valor curricular de 240 horas académicas. Para obtener éste certificado debe obtener los módulos I al II.',
        fechapublicacion: '20 de enero de 2021',
        activo: BlockIcon,
        registrarse: false,
        certificado: true,
        sesiones: false,
        cdescarga: 'f_ffv1mg',

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/ffversion1/c7_mg.jpg',
        orientacion: 'landscape',
        nombrestop: 190,
        nombresleft: 80,
        nombresright: 150,
        qrtop: 170,
        qrleft: 640,
        citop: 155,
        cileft: 640,
    },
    { 
        id: 2,
        titulo: 'MÓDULO II - Formación de Facilitadores en Plataformas y Herramientas Virtuales para la Educación',
        img: '/img/cursosytalleres/c7_2.jpg',
        descripcion: 'Formación de Facilitadores en Plataformas y Herramientas Virtuales para la Educación, Certificado con valor curricular de 100 horas académicas.',
        fechapublicacion: '4 de enero de 2021',
        activo: BlockIcon,
        registrarse: false,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_ffv1m2',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: Pizarras virtuales interactivas, Notebookcast, Limnu, Whiteboard, Awwapp',
                sesionlink: 'https://fb.watch/2OTOHZlDLp/',
            },
            {
                sesiondesc: 'SESIÓN 2: VodeoConferencias con; Google Meet, StreamYard, Facebook live, Youtube',
                sesionlink: 'https://fb.watch/2OTPCFCigS/',
            },
        ],

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/ffversion1/c7_m2.jpg',
        orientacion: 'landscape',
        nombrestop: 190,
        nombresleft: 80,
        nombresright: 150,
        qrtop: 170,
        qrleft: 640,
        citop: 155,
        cileft: 640,
    },
    { 
        id: 1,
        titulo: 'MÓDULO I - Formación de Facilitadores en Plataformas y Herramientas Virtuales para la Educación', 
        img: '/img/cursosytalleres/c7_1.jpg',
        descripcion: 'Formación de Facilitadores en Plataformas y Herramientas Virtuales para la Educación, Certificado con valor curricular de 100 horas académicas.',
        fechapublicacion: '20 de diciembre de 2020',
        activo: BlockIcon,
        registrarse: false,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_ffv1m1',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: La función docente del facilitador en línea, Introducción a ClassRoom.',
                sesionlink: 'https://fb.watch/2xJxvOZQYF/',
            },
            {
                sesiondesc: 'SESIÓN 2: Bases pedagógicas de la educación en línea, Entornos virtuales de aprendizaje (EVA).',
                sesionlink: 'https://fb.watch/2OTp5ehDHX/',
            },
            {
                sesiondesc: 'SESIÓN 3: Google para la educación, classroom, calendar, gDrive.',
                sesionlink: 'https://fb.watch/2OTAQIKMuB/',
            },
        ],

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/ffversion1/c7_m1.jpg',
        orientacion: 'landscape',
        nombrestop: 190,
        nombresleft: 80,
        nombresright: 150,
        qrtop: 170,
        qrleft: 640,
        citop: 155,
        cileft: 640,
    }
];

const sidebar = {
    title: 'Aviso',
    description: 'Cursos y talleres virtuales que realizamos lo transmitimos via facebook live, zoom y tambien en youtube. En esas redes sociales nos puede encontrar con los diversos talleres que ya se realizaron. Nuestros cursos y talleres virtuales son asíncronos, estan diseñados y elaborados para que se adapten al horario del participante. Pueden ingresar en la comodidad de su tiempo a ver las sesiones grabadas, mismos que están publicados aquí, también pueden certificarse en cualquier momento.',
    archives: [
        { title: 'Exámenes onLine - EXONLI', url: 'https://examenesonline.cybermaq.com', descripcion: 'Elaborar en menos de 5 minutos tus exámenes en línea.' },
        { title: 'TrabajoPROFES', url: 'https://trabajoprofes.cybermaq.com', descripcion: 'Publica y revisa tus permutas, suplencias, ofertas laborales y publicitar tu especialidad.' }
    ],
    social: [
        { name: 'Página Facebook', icon: FacebookIcon, url: 'https://www.facebook.com/Docente-Digital-115744910277566' },
        { name: 'Grupo Telegram', icon: SendTelegram, url: 'https://t.me/docentedigitalcybermaq'},
        { name: 'Grupo Facebook', icon: FacebookIcon, url: 'https://www.facebook.com/groups/353619069077252' },
        { name: 'Grupo Signal', icon: MessageRoundedIcon, url: 'https://signal.group/#CjQKIBPiMO2nwcTgNXzhabU7S6zepWPulOef-EqGckPUSwfLEhA3Wwy_ofbQpnGztV32QvkT' },
        { name: 'Grupo 1 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/FKA6qa1g8PgFYeINf9s3DY' },
        { name: 'Grupo 2 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/J8ej4ipnFi87gHyUu5BapX' },
        { name: 'Grupo 3 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/CrW90fRcBCr0xfLCPOwrYL' },
        { name: 'Grupo 4 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/FOdDZXOGDmn1FXqGDH1ZvC' },
        { name: 'Grupo 5 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/CA1jhw411I3KV1giJzcesR' },
        { name: 'Grupo 6 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/CHV8EMxz5kwC811JjiznUY' },
        { name: 'Grupo 7 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/LBfUc9W7VypEvbeRtFy3O7' },
        { name: 'Grupo 8 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/Gp1iOpdqhD8DLwQyIFHNOS' },
        { name: 'Grupo 9 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/E1Y7Qg0MqirIOVbge3pPld' },
        { name: 'Grupo 10 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/JMUMfIe9UDMIxj2PMiQfAm' },
        { name: 'Twitter', icon: TwitterIcon, url: 'https://www.twitter.com/' },
        { name: 'Canal Youtube', icon: YoutubeIcon, url: 'https://www.youtube.com/channel/UC2f6BQj1Pe6Ox-rHy8Hjchg' },
        { name: 'Grupo Maestr@s de nivel INICIAL', icon: FacebookIcon, url: 'https://www.facebook.com/groups/2051894518285257' },
        { name: 'Grupo Maestr@s de nivel PRIMARIA', icon: FacebookIcon, url: 'https://www.facebook.com/groups/438690387347347' },
        { name: 'Grupo Maestr@s de nivel SECUNDARIA', icon: FacebookIcon, url: 'https://www.facebook.com/groups/481813622825881' },
    ],
};

export default function Cursos2021() {
    const classes = useStyles();
    return ( <React.Fragment>
        <CssBaseline/>
        <Container maxWidth = "lg" >
            <Header2 title="Cursos y Talleres virtuales 2021" /> 
            <main>
                <Carrusel/> 
                
                <Grid container spacing = { 5 } className = { classes.mainGrid } >
                    <Main2 
                        title = "Cursos y talleres virtuales, que se realizan se publican aquí." 
                        posts = { posts }
                        sesionesgrabadas = { posts.sesionesgrabadas }
                        /> 
                    <Sidebar title = { sidebar.title }
                        description = { sidebar.description }
                        archives = { sidebar.archives }
                        social = { sidebar.social }/> 
                </Grid> 
            </main> 
        </Container>
        <Footer title = "Docente Digital CyberMAQ" description = "Más información puede comunicarse con 62536641 - 75548200 - 70117490" / >
        </React.Fragment>
    );
}