import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SendTelegram from '@material-ui/icons/Telegram';
import FacebookIcon from '@material-ui/icons/Facebook';
import BlockIcon from '@material-ui/icons/Block';
import TwitterIcon from '@material-ui/icons/Twitter';
import YoutubeIcon from '@material-ui/icons/YouTube';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import MessageRoundedIcon from '@material-ui/icons/MessageRounded';
import Header2 from './Header2';
import Main2 from './Main2';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Carrusel from '../componentes/Carrusel';


const useStyles = makeStyles((theme) => ({
    mainGrid: {
        marginTop: theme.spacing(3),
    }
}));

const posts = [
    { 
        id: 10,
        titulo: 'Entornos Virtuales y TICs en la Educación ', 
        img: '/img/cursosytalleres/c6.jpg', 
        descripcion: 'Taller Virtual - Moodle, ClassRoom, Pizarras digitales, WhiteBoard, Padlet, Aplicaciones Windows y Android, Certificado con valor curricular de 180 horas académicas.',
        fechapublicacion: '16 de noviembre de 2020',
        activo: BlockIcon,
        registrarse: false,
        certificado: false,
        sesiones: true,
        cdescarga: 'f_ticupea'
    },
    { 
        id: 9,
        titulo: 'MÓDULO V - Herramientas Digitales para Transformar la Educación Virtual y Presencial con Aplicaciones multidisciplinarias.', 
        img: '/img/cursosytalleres/c5_5.jpg', 
        descripcion: '1er CICLO: Prepárate para tus clases virtuales y presenciales con herramientas digitales en gamificar el aprendizaje de tus estudiantes, Certificado con valor curricular de 60 horas académicas.',
        fechapublicacion: '3 de diciembre de 2020',
        activo: BlockIcon,
        registrarse: false,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_c1hdm5',

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/1ciclo/c5_m5.jpg',
        orientacion: 'landscape',
        nombrestop: 180,
        nombresleft: 150,
        nombresright: 100,
        qrtop: 150,
        qrleft: 680,
        citop: 135,
        cileft: 680,
    },
    { 
        id: 8,
        titulo: 'MÓDULO IV - Herramientas Digitales para Transformar la Educación Virtual y Presencial con Aplicaciones multidisciplinarias.', 
        img: '/img/cursosytalleres/c5_4.jpg', 
        descripcion: '1er CICLO: Prepárate para tus clases virtuales y presenciales con herramientas digitales en gamificar el aprendizaje de tus estudiantes, Certificado con valor curricular de 60 horas académicas.',
        fechapublicacion: '25 de noviembre de 2020',
        activo: BlockIcon,
        registrarse: false,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_c1hdm4',

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/1ciclo/c5_m4.jpg',
        orientacion: 'landscape',
        nombrestop: 180,
        nombresleft: 150,
        nombresright: 100,
        qrtop: 150,
        qrleft: 680,
        citop: 135,
        cileft: 680,
    },
    { 
        id: 7,
        titulo: 'MÓDULO III - Herramientas Digitales para Transformar la Educación Virtual y Presencial con Aplicaciones multidisciplinarias.', 
        img: '/img/cursosytalleres/c5_3.jpg', 
        descripcion: '1er CICLO: Prepárate para tus clases virtuales y presenciales con herramientas digitales en gamificar el aprendizaje de tus estudiantes, Certificado con valor curricular de 60 horas académicas.',
        fechapublicacion: '16 de noviembre de 2020',
        activo: BlockIcon,
        registrarse: false,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_c1hdm3',

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/1ciclo/c5_m3.jpg',
        orientacion: 'landscape',
        nombrestop: 180,
        nombresleft: 150,
        nombresright: 100,
        qrtop: 150,
        qrleft: 680,
        citop: 135,
        cileft: 680,
    },
    { 
        id: 6,
        titulo: 'MÓDULO II - Herramientas Digitales para Transformar la Educación Virtual y Presencial con Aplicaciones multidisciplinarias.', 
        img: '/img/cursosytalleres/c5_2.jpg', 
        descripcion: '1er CICLO: Prepárate para tus clases virtuales y presenciales con herramientas digitales en gamificar el aprendizaje de tus estudiantes, Certificado con valor curricular de 60 horas académicas.',
        fechapublicacion: '6 de noviembre de 2020',
        activo: BlockIcon,
        registrarse: false,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_c1hdm2',

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/1ciclo/c5_m2.jpg',
        orientacion: 'landscape',
        nombrestop: 180,
        nombresleft: 150,
        nombresright: 100,
        qrtop: 150,
        qrleft: 680,
        citop: 135,
        cileft: 680,
    },
    { 
        id: 5,
        titulo: 'MÓDULO I - Herramientas Digitales para Transformar la Educación Virtual y Presencial con Aplicaciones multidisciplinarias.', 
        img: '/img/cursosytalleres/c5_1.jpg', 
        descripcion: '1er CICLO: Prepárate para tus clases virtuales y presenciales con herramientas digitales en gamificar el aprendizaje de tus estudiantes, Certificado con valor curricular de 60 horas académicas.',
        fechapublicacion: '28 de octubre de 2020',
        activo: BlockIcon,
        registrarse: false,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_c1hdm1',

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/1ciclo/c5_m1.jpg',
        orientacion: 'landscape',
        nombrestop: 180,
        nombresleft: 150,
        nombresright: 100,
        qrtop: 150,
        qrleft: 680,
        citop: 135,
        cileft: 680,
    },
    { 
        id: 4,
        titulo: 'Curso Nacional: Google For Education - GRUPO B', 
        img: '/img/cursosytalleres/c4.jpg', 
        descripcion: 'Google para la educación, Meet, GDrive, Gmail, calendar, Docs, Slides, Sheets, Forms, ClassRoom, Jamboard, Keep, Blogger, Youtube, Streamyard, Exámenes onLine y Prezi, Certificado con valor curricular de 120 horas académicas.',
        fechapublicacion: '7 de octubre de 2020',
        activo: BlockIcon,
        registrarse: false,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_gfegrupob',

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/c4_gfeb.jpg',
        orientacion: 'landscape',
        nombrestop: 180,
        nombresleft: 150,
        nombresright: 100,
        qrtop: 150,
        qrleft: 680,
        citop: 135,
        cileft: 680,
    },
    { 
        id: 3,
        titulo: 'Curso Nacional: Google For Education - GRUPO A', 
        img: '/img/cursosytalleres/c3.jpg',
        descripcion: 'Google, Meet, GDrive, Gmail, calendar, Docs, Slides, Sheets, Forms, ClassRoom, Jamboard, Keep, Blogger, Youtube, Exámenes onLine y Prezi, Certificado con valor curricular de 120 horas académicas.',
        fechapublicacion: '10 de septiembre de 2020',
        activo: BlockIcon,
        registrarse: false,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_gfegrupoaupea',
        
        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/c3_gfea.jpg',
        orientacion: 'landscape',
        nombrestop: 235,
        nombresleft: 200,
        nombresright: 20,
        qrtop: 150,
        qrleft: 680,
        citop: 135,
        cileft: 680,
    },
    { 
        id: 2,
        titulo: 'Educación Digital Multidisciplinaria - Integración de herramientas GSUITE', 
        img: '/img/cursosytalleres/c2.jpg',
        descripcion: 'Google meet, google Grive, Gmail, google Calendar, Docs, Slides, Sheets, Form, ClassRoom, Jamboard, Certificado con valor curricular de 120 horas académicas.',
        fechapublicacion: '20 de agosto de 2020',
        activo: BlockIcon,
        registrarse: false,
        certificado: false,
        sesiones: true,
        cdescarga: 'f_edmupea'
    },
    { 
        id: 1,
        titulo: 'Herramientas Tecnológicas para clases virtuales', 
        img: '/img/cursosytalleres/c1.jpg',
        descripcion: 'Zoom, Jitsi Meet, ClassRoom, Exámenes onLine, google Meet.',
        fechapublicacion: '22 de junio de 2020',
        activo: BlockIcon,
        registrarse: false,
        certificado: false,
        sesiones: false
    }
];

const sidebar = {
    title: 'Aviso',
    description: 'Cursos y talleres virtuales que realizamos lo transmitimos via facebook live, zoom y tambien en youtube. En esas redes sociales nos puede encontrar con los diversos talleres que ya se realizaron. Nuestros cursos y talleres virtuales son asíncronos, estan diseñados y elaborados para que se adapten al horario del participante. Pueden ingresar en la comodidad de su tiempo a ver las sesiones grabadas, mismos que están publicados aquí, también pueden certificarse en cualquier momento.',
    archives: [
        { title: 'Exámenes onLine - EXONLI', url: 'https://examenesonline.cybermaq.com', descripcion: 'Elaborar en menos de 5 minutos tus exámenes en línea.' },
        { title: 'TrabajoPROFES', url: 'https://trabajoprofes.cybermaq.com', descripcion: 'Publica y revisa tus permutas, suplencias, ofertas laborales y publicitar tu especialidad.' }
    ],
    social: [
        { name: 'Página Facebook', icon: FacebookIcon, url: 'https://www.facebook.com/Docente-Digital-115744910277566' },
        { name: 'Grupo Telegram', icon: SendTelegram, url: 'https://t.me/docentedigitalcybermaq'},
        { name: 'Grupo Facebook', icon: FacebookIcon, url: 'https://www.facebook.com/groups/353619069077252' },
        { name: 'Grupo Signal', icon: MessageRoundedIcon, url: 'https://signal.group/#CjQKIBPiMO2nwcTgNXzhabU7S6zepWPulOef-EqGckPUSwfLEhA3Wwy_ofbQpnGztV32QvkT' },
        { name: 'Grupo 1 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/FKA6qa1g8PgFYeINf9s3DY' },
        { name: 'Grupo 2 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/J8ej4ipnFi87gHyUu5BapX' },
        { name: 'Grupo 3 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/CrW90fRcBCr0xfLCPOwrYL' },
        { name: 'Grupo 4 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/FOdDZXOGDmn1FXqGDH1ZvC' },
        { name: 'Grupo 5 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/CA1jhw411I3KV1giJzcesR' },
        { name: 'Grupo 6 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/CHV8EMxz5kwC811JjiznUY' },
        { name: 'Grupo 7 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/LBfUc9W7VypEvbeRtFy3O7' },
        { name: 'Grupo 8 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/Gp1iOpdqhD8DLwQyIFHNOS' },
        { name: 'Grupo 9 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/E1Y7Qg0MqirIOVbge3pPld' },
        { name: 'Grupo 10 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/JMUMfIe9UDMIxj2PMiQfAm' },
        { name: 'Twitter', icon: TwitterIcon, url: 'https://www.twitter.com/' },
        { name: 'Canal Youtube', icon: YoutubeIcon, url: 'https://www.youtube.com/channel/UC2f6BQj1Pe6Ox-rHy8Hjchg' },
        { name: 'Grupo Maestr@s de nivel INICIAL', icon: FacebookIcon, url: 'https://www.facebook.com/groups/2051894518285257' },
        { name: 'Grupo Maestr@s de nivel PRIMARIA', icon: FacebookIcon, url: 'https://www.facebook.com/groups/438690387347347' },
        { name: 'Grupo Maestr@s de nivel SECUNDARIA', icon: FacebookIcon, url: 'https://www.facebook.com/groups/481813622825881' },
    ],
};

export default function Cursos2020() {
    const classes = useStyles();
    return ( <React.Fragment>
        <CssBaseline/>
        <Container maxWidth = "lg" >
            <Header2 title="Cursos y Talleres virtuales 2020" /> 
            <main>
                <Carrusel/> 
                
                <Grid container spacing = { 5 } className = { classes.mainGrid } >
                    <Main2 title = "Cursos y talleres virtuales, que se realizan se publican aqui." posts = { posts }/> 
                    <Sidebar title = { sidebar.title }
                        description = { sidebar.description }
                        archives = { sidebar.archives }
                        social = { sidebar.social }/> 
                </Grid> 
            </main> 
        </Container>
        <Footer title = "Docente Digital CyberMAQ" description = "Más información puede comunicarse con 62536641 - 75548200 - 70117490" / >
        </React.Fragment>
    );
}