import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SendTelegram from '@material-ui/icons/Telegram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YoutubeIcon from '@material-ui/icons/YouTube';
import MessageRoundedIcon from '@material-ui/icons/MessageRounded';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Carrusel from '../componentes/Carrusel';
import Spinner from '../componentes/Spinner';


const useStyles = makeStyles((theme) => ({
    mainGrid: {
        marginTop: theme.spacing(3),
    },
}));

const sections = [
    { title: 'Technology', url: '#' },
    { title: 'Design', url: '#', active: true },
    { title: 'Culture', url: '#' },
    { title: 'Business', url: '#' },
    { title: 'Politics', url: '#' },
    { title: 'Opinion', url: '#' },
    { title: 'Science', url: '#' },
    { title: 'Health', url: '#' },
    { title: 'Style', url: '#' },
    { title: 'Travel', url: '#' },
];

const mainFeaturedPost = {
    title: 'Docente Digital CyberMAQ',
    description: "Comunidad Digital donde realizamos cursos, talleres, capacitaciones, webinar's y asesoramiento técnico tecnológico para la educación y otros rubros. Servicio integrado a nivel Nacional sobre tecnología informática.",
    image: '/img/banner/b3.jpg',
    imgText: 'www.cybermaq.com',
    linkText: 'Informaciones',
};

const featuredPosts = [{
        title: 'Cursos y talleres virtuales',
        date: '2021',
        description: 'Entrar aqui para ver los cursos y talleres virtuales que se están desarrollando en ésta gestión.',
        image: '/img/2.jpg',
        imageText: 'www.cybermaq.com',
        url: '/cursos2021'
    },
    {
        title: 'Cursos y talleres virtuales',
        date: '2020',
        description: 'Entrar aqui y encontraras los cursos y talleres virtuales realizados en la gestión del 2020.',
        image: '/img/1.jpg',
        imageText: 'www.cybermaq.com',
        url: '/cursos2020'
    },
];

const posts = [
    // {   
    //     titulo: 'CURSO INTERNACIONAL: Google for education - nivel Avanzado.',
    //     img: '/img/cursosytalleres/c11_1.jpg',
    //     descripcion: 'Curso internacional, donde se tocaran temas sobre toda las herramientas de GSUITE que ofrece google de manera gratuita para la educación. Capacitación desde lo básico al avanzado.',
    //     fechapubli: '6 de abril 2021',
    //     grupo1: 'https://chat.whatsapp.com/HbeJsM0IL1JKHDU9vILLsD',
    //     grupo2: 'https://chat.whatsapp.com/JipgNqHTO0YK9QeT1QTZ3B'
    // },
    // {   
    //     titulo: 'CURSO INTERNACIONAL: Google for education - nivel Básico.', 
    //     img: '/img/cursosytalleres/c11_2.jpg',
    //     descripcion: 'Curso internacional, donde se tocaran temas sobre toda las herramientas de GSUITE que ofrece google de manera gratuita para la educación. Capacitación desde lo básico al avanzado.',
    //     fechapubli: '6 de abril 2021',
    //     grupo1: 'https://chat.whatsapp.com/HbeJsM0IL1JKHDU9vILLsD',
    //     grupo2: 'https://chat.whatsapp.com/JipgNqHTO0YK9QeT1QTZ3B'
    // },
    // {   
    //     titulo: 'MOODLE plataforma de MINEDU - Bolivia NIVEL SECUNDARIA', 
    //     img: '/img/cursosytalleres/c10_3.jpg',
    //     descripcion: 'Curso para Maestros de NIVEL SECUNDARIA, inicia el 4 de marzo donde aprenderas a utilizar la plataforma Educativa del Ministerio de Educacion desde cero paso a paso.',
    //     fechapubli: '5 de marzo 2021',
    //     grupo1: 'https://chat.whatsapp.com/DKENX7fqstg9mrzZbKo9G6',
    //     grupo2: 'https://chat.whatsapp.com/Cqef2eaiDCJ6gyoPldAUkv'
    // }
];

const sidebar = {
    title: 'Transmisiones',
    description: 'Nuestros cursos y talleres virtuales son asíncronos, estan diseñados y elaborados para que se adapten al horario del participante. Pueden ingresar en la comodidad de su tiempo a ver las sesiones grabadas, mismos que están publicados aquí.',
    archives: [
        { title: 'Exámenes onLine - EXONLI', url: 'https://examenesonline.cybermaq.com', descripcion: 'Elaborar en menos de 5 minutos tus exámenes en línea.' },
        { title: 'TrabajoPROFES', url: 'https://trabajoprofes.cybermaq.com', descripcion: 'Publica y revisa tus permutas, suplencias, ofertas laborales y publicitar tu especialidad.' }
    ],
    social: [
        { name: 'Página Facebook', icon: FacebookIcon, url: 'https://www.facebook.com/Docente-Digital-115744910277566' },
        { name: 'Grupo Telegram', icon: SendTelegram, url: 'https://t.me/docentedigitalcybermaq'},
        { name: 'Grupo Facebook', icon: FacebookIcon, url: 'https://www.facebook.com/groups/353619069077252' },
        { name: 'Grupo Signal', icon: MessageRoundedIcon, url: 'https://signal.group/#CjQKIBPiMO2nwcTgNXzhabU7S6zepWPulOef-EqGckPUSwfLEhA3Wwy_ofbQpnGztV32QvkT' },
        { name: 'Grupo 1 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/FKA6qa1g8PgFYeINf9s3DY' },
        { name: 'Grupo 2 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/J8ej4ipnFi87gHyUu5BapX' },
        { name: 'Grupo 3 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/CrW90fRcBCr0xfLCPOwrYL' },
        { name: 'Grupo 4 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/FOdDZXOGDmn1FXqGDH1ZvC' },
        { name: 'Grupo 5 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/CA1jhw411I3KV1giJzcesR' },
        { name: 'Twitter', icon: TwitterIcon, url: 'https://www.twitter.com/' },
        { name: 'Canal Youtube', icon: YoutubeIcon, url: 'https://www.youtube.com/channel/UC2f6BQj1Pe6Ox-rHy8Hjchg' },
        { name: 'Grupo Maestr@s Ed. INICIAL', icon: FacebookIcon, url: 'https://www.facebook.com/groups/2051894518285257' },
        { name: 'Grupo Maestr@s Ed. PRIMARIA', icon: FacebookIcon, url: 'https://www.facebook.com/groups/438690387347347' },
        { name: 'Grupo Maestr@s Ed. SECUNDARIA', icon: FacebookIcon, url: 'https://www.facebook.com/groups/481813622825881' },
    ],
};

export default function Blog() {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const cambiarEstado=()=>{
        setLoading(true);
        setTimeout(()=>{
            setLoading(false);
        },5000)
    }
    if(loading){
        return(
            <Spinner/>
        )
    } else {
        return ( <React.Fragment>
            <CssBaseline/>
            <Container maxWidth = "lg" >
                <Header title="Docente Digital CyberMAQ" sections={sections}/> 
                <main>
                    <MainFeaturedPost post = { mainFeaturedPost }/>
                    <Carrusel/> 
                    <Grid container spacing = { 4 } > {
                        featuredPosts.map((post) => ( <FeaturedPost
                            FeaturedPost key = { post.title }
                            post = { post }
                            />
                        ))
                    } 
                    </Grid> 
                    <Grid container alignItems="flex-start" spacing = { 2 } className = { classes.mainGrid } >
                    <Main title = "Noticias y actualidades que se realizan y realizarán." posts = { posts }/> 
                    <Sidebar title = { sidebar.title }
                    description = { sidebar.description }
                    archives = { sidebar.archives }
                    social = { sidebar.social }/> 
                    </Grid> 
                </main> 
            </Container>
            <Footer title = "Docente Digital CyberMAQ" description = "Más información puede comunicarse con 62536641 - 75548200" / >
            </React.Fragment>
        );
    }
}