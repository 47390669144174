import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Markdown from './Markdown';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import {
  Avatar,
  Button,
  Link,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import {
  Share,
  Create,
} from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';


const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 'auto',
    flexGrow: 1,
  },
  media: {
    height: 0,
    paddingTop: '60%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function Main(props) {
  const classes = useStyles();
  const { posts, title } = props;

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid className={classes.root} container item xs={12} md={8} spacing={2}>
      <Typography variant="h6">
          {title}
      </Typography>
      <Divider />

      {posts.map((post) => (
      <Grid item xs={12} sm={6}>
            <Card className={classes.root} key={post.img}>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe" className={classes.avatar}>
                    ON
                  </Avatar>
                }
                action={
                  <IconButton aria-label="settings">
                    <MoreVertIcon />
                  </IconButton>
                }
                title={post.titulo}
                subheader={post.fechapubli}
              />
              <CardMedia
                className={classes.media}
                image={post.img}
                title="www.cybermaq.com"
              />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                  {post.descripcion}
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton 
                rel="noreferrer" // rel="noopener"
                target="_blank"
                href = "https://www.facebook.com/Docente-Digital-115744910277566"
                aria-label="add to favorites">
                <FavoriteIcon />
              </IconButton>
              <IconButton 
                rel="noreferrer" // rel="noopener"
                target="_blank"
                href = "https://api.whatsapp.com/send?text=Te%20recomiendo%20esta%20plataforma%20de%20cursos%20virtuales%20me%20ha%20ayudado%20mucho%20https://docentedigital.cybermaq.com"
                aria-label="share">
                <Share />
              </IconButton>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent><Divider />
                <Typography paragraph>Participar:</Typography>
                <Typography paragraph>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    style= {{width: 280}}
                    href= "/cursos2021"
                    startIcon={<Create />}> Inscribirme
                  </Button>
                  <br />
                  <Typography paragraph>Unirse a un grupo para mas información:</Typography>
                  <Link 
                          display="block" 
                          variant="body1" 
                          href=  {post.grupo1}
                          rel="noreferrer" // rel="noopener"
                          target="_blank"> 
                          Unirme al grupo 1
                  </Link> 
                  <Link 
                          display="block" 
                          variant="body1" 
                          href= {post.grupo2}
                          rel="noreferrer" // rel="noopener"
                          target="_blank"> 
                          Unirme al grupo 2
                  </Link>   
                </Typography>
              </CardContent>
            </Collapse>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

Main.propTypes = {
  posts: PropTypes.array,
  title: PropTypes.string,
};
